import React, { useState } from "react";
import "./Header.scss";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
function Header() {
  const [tabSelected, tabSelectChange] = useState("home");
  const [hamSelect, hamSelectChange] = useState(false);
  const [onTop, setOnTop] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 850px)` });
  const handleClickTab = (seleted) => {
    tabSelectChange(seleted);
  };
  const handleClickHam = () => {
    hamSelectChange(!hamSelect);
  };
  useEffect(()=>{
    window.addEventListener('scroll',()=>{
       if(window.scrollY > 30 ) {
        setOnTop(false);
         hamSelectChange(false)
       }
       else setOnTop(true);
    });
  },[])
  return (
    <>
        <nav id="navbar-party" className={`${onTop ? "transparent" : "translucent"} myNav `}>
          <div className="navbar-party-logo">
            <img src="/assets/images/logo.png" alt="" className="logo" />
          </div>

          <ul
            className={
              isMobile && hamSelect
                ? "navbar-party-ul d-block"
                : !isMobile
                ? "navbar-party-ul"
                : "navbar-party-ul d-none"
            }
          >
            <li onClick={() => handleClickTab("home")}>
              <a
                className={tabSelected === "home" ? "activatedTab" : ""}
                href="#home"
              >
                Home
              </a>
            </li>
            <li onClick={() => handleClickTab("about")}>
              <a
                className={tabSelected === "about" ? "activatedTab" : ""}
                href="#about"
              >
                About Us
              </a>
            </li>
            <li onClick={() => handleClickTab("events")}>
              <a
                className={tabSelected === "events" ? "activatedTab" : ""}
                href="#events"
              >
                Events
              </a>
            </li>
            <li onClick={() => handleClickTab("gallery")}>
              <a
                className={tabSelected === "gallery" ? "activatedTab" : ""}
                href="#gallery"
              >
                Gallery
              </a>
            </li>
            <li onClick={() => handleClickTab("blog")}>
              <a
                className={tabSelected === "blog" ? "activatedTab" : ""}
                href="#blog"
              >
                Blog
              </a>
            </li>
          </ul>
          <div className="hamburger" onClick={handleClickHam}>
            <div className="hamline"></div>
            <div className="hamline"></div>
            <div className="hamline"></div>
          </div>
          <a className="home-container" href="#home" onClick={() => handleClickTab("home")}>
          <FontAwesomeIcon color="red" size="4x" icon={faHome} className="home-icon" />
          </a>
          <a className="home-container instagram-nav" href="https://www.instagram.com/partybulls555/" target={"_blank"} rel="noreferrer">
            <FontAwesomeIcon color="red" size="4x" icon={faInstagram}  className="home-icon" />
          </a>
        </nav>
    </>
  );
}

export default Header;
