import React, {useState} from "react";
import './About.scss'



const aboutKnowMore = [
    {
        title: "What we do?",
        desc: "We are a collective of enthusiastic individuals driven by the joy of celebration, the thrill of forging connections, and the art of cultivating new friendships. Every dawn presents the canvas for a fresh experience, and we revel in the beauty of the unknown. In a world where responsibilities often pull us in different directions, we recognize the challenge of aligning schedules with friends and colleagues. This is where we step in – a platform that bridges the gap and offers the prospect of shared moments. Our venture is founded on the belief that synchronizing interests and leisure time can be effortless, yielding the perfect occasions for revelry. We curate an environment where kindred spirits converge, where a camaraderie of passions converges with the harmony of schedules. Here, the mundane transforms into the exceptional, turning everyday encounters into remarkable memories."
    },
    {
        title: "How we meet?",
        desc: `<h5>Elevate Your Experience: Join Our Unforgettable Events!</h5><br/>

        Expressing interest is effortless – simply complete our Google Form or reach out on Instagram. Upon receiving your insights, we'll send you an exclusive event invitation with all the details you need.
        
        At the event, your safety and belongings are your responsibility. Once we convene, introductions lead to connections, and the party begins. We embrace individuality within respectful bounds, ensuring a fun-filled experience for all.
        
        <br/>Join us as we redefine celebration – where every event is a canvas for your enjoyment and connections flourish.`
    },
    {
        title: "How we party?",
        desc: `Our party philosophy is all about creating an atmosphere where everyone can let loose and enjoy themselves.
        "Commence the Celebration: Where Every Moment Matters"

        Upon assembly, the festivities ignite. PartyBulls ensures that any exclusive event discounts secured are seamlessly extended to all attendees, epitomizing our commitment to inclusivity.

        We revel in an evening of dancing, camaraderie, and libations – a culmination of celebration that endures through the night. For those desiring an early exit, the freedom to depart at your discretion is respected.

        At event closure, a collective group photo serves as a testament to our shared experience. Individualized collage photos stand as personal mementos of the occasion. Recognizing diverse preferences, participants are welcome to exclude their image from public display.

        Our purpose echoes clearly: to forge connections and cultivate joy. PartyBulls beckons you to partake in a journey where new friendships flourish, and the pursuit of amusement knows no bounds.
        `
    },
]
function About() {
    const [selectedAbout, setSelectedAbout] = useState(-1);
    const handleAboutHandler = (index)=>{
        setSelectedAbout(index)
    }
    return ( 
    <section id="about" className="section-separator">
        {selectedAbout >= 0 &&<div className="overlay-shadow" onClick={()=>setSelectedAbout(-1)}>
                    <div data-aos="zoom-in" className="popup-text-container" onClick={(event)=>event.stopPropagation()}>
                        <h1 className="heading-popup">{aboutKnowMore[selectedAbout].title}</h1>
                        <div className="text-popup" dangerouslySetInnerHTML={{__html:aboutKnowMore[selectedAbout].desc}}/>
                    
                        <div className="text-center about-btn-container mt-1 pt-3">
                        <a className="button-border-filled " href="#events" onClick={()=>setSelectedAbout(-1)}>
                            View Events
                        </a>
                        </div>
                    
                        <div onClick={()=>setSelectedAbout(-1)} className="crossSign-secondary">X</div>
                    </div>       
            </div>}
        <h1 className="heading" data-aos="zoom-in" data-aos-duration="1000"> About Us</h1>
        <p className="sub-heading" data-aos="zoom-in" data-aos-duration="1000">Let's understand how We Craft Spectacular Events</p>
        <div className="line" data-aos="zoom-in"></div>
        <div className="section-separator-heading"></div>
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="about-boxes">
                        <h2 className="heading2">
                            What we do?
                        </h2>
                        <div className="image-about">
                            <img src="/assets/images/about1.jpeg" className="img-fluid" alt="AboutImg"/>
                            <div className="overlay-about first-overlay" onClick={()=>handleAboutHandler(0)}></div>
                        </div>
                        <p className="pt-3 ">
                             Turning Dreams into Unforgettable Events:
                            <br/> Crafting Extraordinary Experiences
                        </p>
                        <button className="button-border-theme" onClick={()=>handleAboutHandler(0)}>
                           know more
                        </button>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <div data-aos="fade-up" data-aos-duration="1000" className="about-boxes">
                         <h2 className="heading2">
                            How we meet?
                        </h2>
                        <div className="image-about">
                            <img src="/assets/images/about2.jpeg" className="img-fluid" alt="AboutImg"/>
                            <div className="overlay-about"  onClick={()=>handleAboutHandler(1)}></div>
                        </div>
                        <p className="pt-3 ">
                            Uniting Cultures, Celebrating Traditions:
                            <br/> Where Diversity Sparks the Party!
                        </p>
                        <button className="button-border-theme"  onClick={()=>handleAboutHandler(1)}>
                           know more
                        </button>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="about-boxes">
                        <h2 className="heading2">
                            how we party?
                        </h2>
                        <div className="image-about">
                            <img src="/assets/images/about3.jpeg" className="img-fluid" alt="AboutImg"/>
                            <div className="overlay-about"  onClick={()=>handleAboutHandler(2)}></div>
                        </div>
                        <p className="pt-3 ">
                            Partying: 
                            <br/> Our Expertise, Your Experience
                        </p>
                        <button className="button-border-theme"  onClick={()=>handleAboutHandler(2)}>
                           know more
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    );
}

export default About;