import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { logout } from '../../actions/LogoutAction';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import { Outlet } from 'react-router';
import './Dashboard.scss'
import useLoginStatus from '../../utilities/hooks/useLoginStatus';
import Footer from '../Footer';

function Dashboard() {
    const [drawerState,setDrawerState] = useState(false)
    const dispatch = useDispatch();
    const reduxAuth = useSelector(state=>state.reducer_auth);
    useLoginStatus()
    const logoutHandler = ()=>{
        dispatch(logout())
    }
    const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={()=>setDrawerState(false)}
          onKeyDown={()=>setDrawerState(false)}
        >
          <List>  
            <img src='/assets/images/logo.png' alt='Logo' className='navLogo'/>
            <Divider/>
            <Link to="/admin/dashboard/events">
              <ListItem disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                    <CelebrationIcon/>
                  </ListItemIcon>
                  <ListItemText primary={"Events"} />
                </ListItemButton>
              </ListItem>
            </Link>
              {reduxAuth.loginData.role==="Admin" && <Link to="/admin/dashboard/users">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <PermIdentityIcon/>
                  </ListItemIcon>
                   <ListItemText primary={"Users"} />
                </ListItemButton>
               </ListItem>
              </Link>}
             
          </List>
         
        </Box>
      );
    return (
         <>
         <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                <IconButton
                    onClick={()=>setDrawerState(!drawerState)}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    
                </Typography>
                <Button  onClick={logoutHandler} color="inherit">Logout</Button>
                </Toolbar>
            </AppBar>
         </Box>

         <SwipeableDrawer
            anchor={'left'}
            open={drawerState}
            onClose={()=>setDrawerState(false)}
            onOpen={()=>setDrawerState(true)}
          >
            {list('left')}
         </SwipeableDrawer>
          <div className='dashboard_container'>
            <Outlet />
          </div>
        <Footer 
        homeLink={`${window.location.origin}/#home`}
        aboutLink={`${window.location.origin}/#about`}
        galleryLink={`${window.location.origin}/#gallery`}
        eventLink={`${window.location.origin}/#events`}
        blogLink={`${window.location.origin}/#blog`}
        />
         </>
         );
}

export default Dashboard;