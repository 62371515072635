import {Link} from 'react-router-dom'
import { useEffect } from "react";
import useLoginStatus from "../../utilities/hooks/useLoginStatus";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {eventFetchAction,deleteEventAction} from '../../actions/EventsAction'
import { Icon } from '@mui/material';
import {useSelector,useDispatch} from 'react-redux'
import './EventsAdmin.scss'
import Spinner from '../Spinner';

function EventsAdmin() {
    const reduxEvent = useSelector(state=>state.reducer_event);
    const reduxAuth = useSelector(state=>state.reducer_auth);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(eventFetchAction())
       },[dispatch])
    useLoginStatus();
    const handleDeleteUser =(eventId,img)=>{
        if(window.confirm("Do you want to delete!"+eventId))
        dispatch(deleteEventAction(eventId,img))
    }
    if(reduxAuth.showSpinner)
    {
        return <div className='spinnerFullWidthContainer'> <Spinner/></div>
    }
    return ( 
    <div className="section-separator">
        <h1 className="text-center mb-3">Events</h1>
        <div className="floating-icon"><Link to="/admin/dashboard/addEvent"><Icon  sx={{cursor:"pointer"}}  fontSize={'large'} component={ControlPointIcon} color="primary"/></Link></div>
        <div className="table-responsive p-3">
        <table className="table">
                <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date and Time</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Link</th>
                    <th scope="col">Active</th>
                    <th scope="col">User</th>
                    <th scope="col">Ticket Type</th>
                    <th scope="col">Dress Code</th>
                    </tr>
                </thead>
                <tbody>
                    {(reduxEvent.fetchData && reduxEvent.fetchData.events && reduxEvent.fetchData.events.length) ? reduxEvent.fetchData.events.map((ele,key)=>{
                    return (
                    <tr key={key}>
                    <th scope="row">{key+1}</th>
                    <td>{ele.title}</td>
                    <td>{ele.desc && ele.desc.slice(0,25)+"..."}</td>
                    <td>{ele.date + " "+ ele.time}</td>
                    <td>{ele.venue}</td>
                    <td>{ele.cost}</td>
                    <td>{ele.link}</td>
                    <td>{ele.active ? <span className='activeSignal'></span> : <span className='nonActiveSignal'></span>}</td>
                    <td>{ele.user}</td>
                    <td>{ele.ticketType}</td>
                    <td>{ele.dressCode}</td>
                    <td onClick={()=>handleDeleteUser(ele.eventId,ele.img)}><Icon sx={{cursor:"pointer"}} fontSize={'small'} component={DeleteForeverIcon} color="primary"/></td>
                    <td><Link to={"/admin/dashboard/updateEvent/"+ele.eventId+""}><Icon  sx={{cursor:"pointer"}} fontSize={'small'} component={ModeEditOutlineIcon} color="primary"/></Link></td>
                    </tr>)
                    }) : <tr className="text-center"><td colSpan={11}><h1>No Events found</h1></td></tr>
                    }
                </tbody>
            </table>
      </div> 
    </div> 
    );
}

export default EventsAdmin;