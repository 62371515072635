import React from "react";
import './DashboardMain.scss'

function DashBoardMain() {
    return ( 
        <div className='dashboard_Main'>
             <h1>Welcome {localStorage.getItem('userName') ? localStorage.getItem('userName').toUpperCase() : null } to your Admin Dashboard!</h1> 
        </div>
     );
}

export default DashBoardMain;