import {Field,reduxForm} from 'redux-form'
import {TextInputComponent, SelectComponent} from '../../utilities/MiniComponents/InputComponent'
import {useDispatch,useSelector} from 'react-redux'
import { gettingUserRoles_Action,UPDATE_USER_RESET } from '../../actions/AuthAction';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
const validate =(value)=>{
    let error = {};
    if(!value.firstName)
         error.firstName = 'Please Enter First Name!'
    if(!value.lastName)
    error.lastName = 'Please Enter Last Name!'
    if(!value.email)
    error.email = 'Please Enter Email!'

    var reg = /^[1-9]{1}[0-9]{9}$/;     
    if(!reg.test(value.phone))
        error.phone = 'Please Enter Valid Phone Number!'

    return error;
    
}
function UpdateUserForm(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {userData} = props;
    const reducerAuth = useSelector(state=>state.reducer_auth)
    const rolesState = reducerAuth.userRoles;
    const updateUserError = reducerAuth.updateUserError;
    const updateUserResponse = reducerAuth.updateUserResponse
    const [roles,setRoles] = useState([]);
    const {initialize} = props;
    useEffect(()=>{
        setRoles(rolesState)
    },[rolesState])

    useEffect(()=>{
        dispatch(gettingUserRoles_Action())
    },[dispatch])

    useEffect(()=>{
       initialize(userData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userData])

    useEffect(()=>{
        if(updateUserResponse && updateUserResponse.isSuccess){
            dispatch({type:UPDATE_USER_RESET})
            navigate('/admin/dashboard/users')
        }
    },[dispatch, navigate, updateUserResponse])
    return ( 
    <form onSubmit={props.handleSubmit} className={'formRegistration'}>
        {updateUserError && <div className='error text-center'>{updateUserError}</div>}
        <Field type={"text"} component={TextInputComponent} placeholder="Enter First Name" name="firstName" className="text_box"/>
        <Field type={"text"} component={TextInputComponent} placeholder="Enter Last Name" name="lastName" className="text_box "/>
        <Field type={"email"} component={TextInputComponent} placeholder="Enter EmailId" name="email" className="text_box"/>
        <Field type={"phone"} component={TextInputComponent} placeholder="Enter Phone Number" name="phone" className="text_box"/>
        <Field component={SelectComponent} name="role" className="text_box" label={"Select the role of admin"}>
            {roles}
        </Field>
        <button disabled={!props.valid} className='button-border-filled mt-3' type='update'>Update User</button>
    </form>
     );
}
const myUpdationForm = reduxForm(
    {
        form: "updateUser",
        validate,
        keepDirtyOnReinitialize: true,
  
    }
)

export default myUpdationForm(UpdateUserForm);