import React from "react";
import Banner from "./Banner";
import About from "./About";
import './Homepage.scss'
import Events from "./Events";
import Gallery from "./Gallery";
import Blog from "./Blog";
import Footer from "./Footer";
import Map from "./Map"
import Bull from "./Bull";
function Homepage() {
    return ( 
        <>
          <Banner/>
          <About/>
          <Events/>
          <Gallery/>
          <Blog/>
          <Bull/>
          <Map/>
          <Footer 
           homeLink={"#home"}
           aboutLink={"#about"}
           galleryLink={"#gallery"}
           eventLink={"#events"}
           blogLink={"#blog"}
           />
        </>
    );
}

export default Homepage;