import {useDispatch,useSelector} from 'react-redux'
import useLoginStatus from "../../utilities/hooks/useLoginStatus";
import RegisterUserForm from "./RegistorUserForm";
import './AddUser.scss'
import { registerUser_Action } from "../../actions/AuthAction";
import Spinner from '../Spinner';

function AddUser() {
    const dispatch = useDispatch();
    const reduxAuth = useSelector(state=>state.reducer_auth);
    useLoginStatus();

    const handleSubmit = (values)=>{
        dispatch(registerUser_Action(values))
    }
    if(reduxAuth.loginData.role !=='Admin'){
        return <div className="section-separator text-center">You are not eligible to access this page</div>
    }
    if(reduxAuth.showSpinner)
    {
        return <div className='spinnerFullWidthContainer'> <Spinner/></div>
    }
    return ( <div className="section-separator">
       <h1 className="text-center">Add User</h1> 
       <RegisterUserForm onSubmit={handleSubmit}/>
    </div> );
}

export default AddUser;