import React from 'react';
import './Spinner.scss'

const Spinner = () => {

    return (
        <>
         <div className='spinner_container'>
            <div className='spinner_outer'>
                <div className='spinner_inner'>

                </div>
            </div>
         </div> 
         <div className='text-center text-white pt-2'>
         Loading...
        </div>
        </>
    );
}

export default Spinner;