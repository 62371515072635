import React, { useState } from "react";
import './Gallery.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
function Gallery() {
   const [selectedPic, setSelectedPic] = useState("");
    const handleGalleryClick = (src)=>{
        setSelectedPic(src)
    }
    return ( 
        <section id="gallery" className="section-separator">
            {selectedPic&&<div className="overlay-shadow" onClick={()=>setSelectedPic("")}>
                <div data-aos="zoom-in" className="frame-pic" onClick={(event)=>event.stopPropagation()}>
                    <img className="frame-pic-image"  alt="pic" src={selectedPic}/>
                    <div onClick={()=>setSelectedPic("")} className="crossSign">X</div>
                </div>
                
            </div>}
            <h1 className="heading" data-aos="zoom-in"> Gallery</h1>
            <p className="sub-heading" data-aos="zoom-in">Step into the Celebration, Explore Our Party Gallery</p>
            <div className="line" data-aos="zoom-in"></div>
            <div className="section-separator-heading"></div>
            <div className="container">
                <div className="row justify-content-center p-6" >
                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid"  alt="" src="/assets/images/gallery1.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery1.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery2.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery2.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery3.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery3.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>


                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery4.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery4.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery9.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery9.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                       
                         </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery6.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery6.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                       
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery7.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery7.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                       
                         </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery8.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery8.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery11.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery11.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery12.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery12.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery13.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery13.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 pb-3">
                        <div className="image" data-aos="zoom-in">
                            <img className="img-fluid" alt="" src="/assets/images/gallery14.jpeg"/>
                            <div className="overlay-gallery-top" onClick={()=>handleGalleryClick("/assets/images/gallery14.jpeg")}>
                                <FontAwesomeIcon icon={faSearchPlus} className="overlay-gallery-top-icon" />
                            </div>                        
                        </div>
                    </div>
                </div>
                <div className="text-center pt-3">
                <a href="https://www.instagram.com/partybulls555/" target={"_blank"} rel="noreferrer"> <button className="button-border-filled">Explore Gallery</button></a>
                </div>
            </div>
        </section>
     );
}

export default Gallery;