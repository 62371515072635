import AddEventForm from "./AddEventForm";
import {useSelector} from 'react-redux'
import useLoginStatus from "../../utilities/hooks/useLoginStatus";
import Spinner from "../Spinner";
function AddEvents() {
    const reduxAuth = useSelector(state=>state.reducer_auth);
    useLoginStatus()
    if(reduxAuth.showSpinner)
    {
        return <div className='spinnerFullWidthContainer'> <Spinner/></div>
    }
    return (
    <div className="section-separator">
       <h1 className="text-center"> Add Events</h1>
       <AddEventForm />
    </div> );
}

export default AddEvents;