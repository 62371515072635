import React from "react";
import './Blog.css'
import { useNavigate } from "react-router-dom";
function Blog(props) {
    const navigate = useNavigate()
    return (  
        <section className="section-separator" id="blog">
                <div className="blog-banner">
                    <h1 className="heading" data-aos="zoom-in">Blogs</h1>
                    <p className="sub-heading text-white" data-aos="zoom-in">Sharing Past Journeys, Chronicles of Places Explored</p>
                    <p className="blog-text" data-aos="zoom-in-up">Discover Our Stories, Our Parties, Our Way!  These are handwritten own experiences of our past. Insights into Our Unique Celebrations and Experiences about places.
                    </p>
                    <div className="text-center pt-3">
                         <button className="button-border-filled" onClick={()=>navigate('/blogs')}>Explore Blogs</button>
                    </div>
                </div>
           
        </section>
    );
}


export default Blog;