import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { eventFetchAction } from "../actions/EventsAction";
import './Events.scss'
import Spinner from "./Spinner";
function Events() {
   const [eventsData, setEventsData] = useState([])
   
   const dispatch = useDispatch();
   let eventDataRedux = useSelector(state=>state.reducer_event)
   const [selectedEvent, setSelectedEvent] = useState("");
   const handleEventHandler = (event)=>{
        setSelectedEvent(event)
    }
    useEffect(()=>{
        dispatch(eventFetchAction());
    },[dispatch])
   
    useEffect(()=>{
        if(eventDataRedux.fetchData && eventDataRedux.fetchData.isSuccess){
            let filteredEventData = eventDataRedux.fetchData.events && eventDataRedux.fetchData.events.length &&  eventDataRedux.fetchData.events.filter(eventObj=>{
            let splittedEvent = eventObj.date.split('-'); //yyyy-mm-dd
            let today =  new Date();
            let todayYear = today.getFullYear().toString();
            let todayDate = today.getDate().toString()
            let todayMonth = (today.getMonth()+1).toString();
            if (todayDate < 10) todayDate = '0' + todayDate;
            if (todayMonth < 10) todayMonth = '0' + todayMonth;
            let splittedEventTime = eventObj.time.split(':'); //hh:mm
            let splittedTodayHour = new Date().getHours().toString()//hh
            if(splittedEvent[0] < todayYear) //year
                return false;
            if(splittedEvent[0] > todayYear)//year
                return true
            if(splittedEvent[1] < todayMonth)//month
                return false;
            if(splittedEvent[1] > todayMonth)//month
                return true;
            if(splittedEvent[2] < todayDate)//date
                return false;
            if(splittedEvent[2] > todayDate)//date
                return true;
            if(splittedEventTime[0] > splittedTodayHour) //hour
                return true;
            else
                return false;
        })
        filteredEventData = filteredEventData && filteredEventData.length && filteredEventData.filter(ele=>ele.active===true)
        if(!filteredEventData)
            setEventsData([])
        else
            setEventsData(filteredEventData)

        }
           
    },[eventDataRedux])
    return (
    <section className="section-separator" id="events">
        {selectedEvent &&<div className="overlay-shadow" onClick={()=>setSelectedEvent("")}>
                <div data-aos="zoom-in" className="popup-text-container-event" onClick={(event)=>event.stopPropagation()}>
                    <h1 className="heading-popup pt-3 mt-3">{selectedEvent.title}</h1>
                    <div className="eventTextpopup" dangerouslySetInnerHTML={{__html:selectedEvent.desc}}/>
                    <div className="text-center m-2 event-popup-button-container">
                        <button className="button-border-filled eventBtn-popup" onClick={()=>window.open(selectedEvent.link)}>
                            Join
                        </button>
                    </div>
                    <div onClick={()=>setSelectedEvent("")} className="crossSign-secondary">X</div>
                </div>
                
            </div>}
        <div className="banner-events">
             <h1 className="heading" data-aos="zoom-in">events</h1>
             <p className="sub-heading custom-white" data-aos="zoom-in">Unlock Remarkable Experiences Through Our Events</p>
             <div className="container pt-3">
                <div className={"row justify-content-center pt-3"}>
                    {(eventsData.length ===0 && eventDataRedux.fetchData.isSuccess) &&  <h3 className="text-center custom-white">Stay tuned for the events!</h3> }
                    {eventDataRedux.fetchData.isSuccess ===false &&  <Spinner/>}
                    {eventsData && Array.isArray(eventsData) && eventsData.length !==0 && eventsData.map((ele,key)=>{
                        return (
                            <div className={`${eventsData.length%3===0 ? "col-12 col-sm-6 col-md-4": eventsData.length%2===0 ? "col-12 col-sm-5" : eventsData.length===1 ? "col-7" : "col-12 col-sm-6 col-md-4"}`} key={key}>     
                                <div className="card event-boxes" data-aos={(key) %2 === 0 ? 'fade-up': 'zoom-in'  } data-aos-duration={(key) %2 === 0 ? 1200: 1400}>
                                        <img src={window.location.origin+'/'+ele.img} className="card-img-top event-image" alt="..."/>
                                        <div className="card-body p-0">
                                            <h5 className="card-title heading2 eventHeading">{ele.title}</h5>
                                            <div className="row justify-content-center">
                                                <div className="col-4">
                                                   <h6 className="heading-6">{new Date(ele.date).toDateString()}</h6>
                                                </div>
                                                <div className="col-4">
                                                     <h6 className="heading-6">{ele.time}&nbsp;Est</h6>
                                                </div >
                                            </div>
                                           
                                            <p className="card-text p-3" dangerouslySetInnerHTML={{__html:ele.desc.slice(0,30)+"..."}}/>
                                            <div>
                                                <h6 className="text-center heading-6">Venue:&nbsp; { ele.venue.toUpperCase()}</h6>
                                            </div>
                                            <div className="row p-3 justify-content-center">
                                                <div className="col-6">
                                                    <button className="button-border-filled eventBtn" onClick={()=>handleEventHandler(ele)}>details</button>
                                                </div>
                                                <div className="col-6">
                                                    <button className="button-border-filled eventBtn" onClick={()=>window.open(ele.link)}>Join</button>
                                                </div >
                                            </div>


                                        </div>
                                        </div>


                            </div>
                        )
                    })}
                </div>
             </div>
          
        </div>
    </section> );
}

export default Events;