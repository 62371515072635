import React from "react";
import { useEffect } from "react";
import {useDispatch,useSelector} from 'react-redux'
import {DeletingUser_Action, gettingUser_Action} from '../../actions/AuthAction'
import useLoginStatus from "../../utilities/hooks/useLoginStatus";
import { Icon } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {Link} from 'react-router-dom'
import './Users.scss'
import Spinner from "../Spinner";


function Users() {
    const dispatch = useDispatch();
    const reduxAuth = useSelector(state=>state.reducer_auth);

    useLoginStatus();
    useEffect(()=>{
        dispatch(gettingUser_Action())
    },[dispatch])
    const userData = reduxAuth.usersData && reduxAuth.usersData.length && reduxAuth.usersData.filter(ele=>ele.userName!==reduxAuth.loginData.userName)

    if(reduxAuth.loginData.role !=='Admin'){
        return <div className="section-separator text-center">You are not eligible to access this page</div>
    }
    const handleDeleteUser =(userName)=>{
        var result = window.confirm("Want to delete "+userName+" ?");
        if (result) {
        dispatch(DeletingUser_Action(userName))
        }
    }
    if(reduxAuth.showSpinner)
    {
        return <div className='spinnerFullWidthContainer'> <Spinner/></div>
    }
    return ( <div className="section-separator">
      <h1 className="text-center">Users</h1> 
      <div className="floating-icon"><Link to="/admin/dashboard/addUser"><Icon  sx={{cursor:"pointer"}}  fontSize={'large'} component={ControlPointIcon} color="primary"/></Link></div>
      <div className="table-responsive p-3">
        <table className="table">
                <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">UserName</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    </tr>
                </thead>
                <tbody>
                    {(userData && userData.length ) ? userData.map((ele,key)=>{
                    return (
                    <tr key={key}>
                    <th scope="row">{key+1}</th>
                    <td>{ele.userName}</td>
                    <td>{ele.firstName +" "+ele.lastName}</td>
                    <td>{ele.phone}</td>
                    <td>{ele.email}</td>
                    <td>{ele.role}</td>
                    <td onClick={()=>handleDeleteUser(ele.userName)}><Icon sx={{cursor:"pointer"}} fontSize={'small'} component={DeleteForeverIcon} color="primary"/></td>
                    <td><Link to={"/admin/dashboard/updateUser/"+ele.userName+""}><Icon  sx={{cursor:"pointer"}} fontSize={'small'} component={ModeEditOutlineIcon} color="primary"/></Link></td>
                    </tr>)
                    }) : <tr className="text-center"><td colSpan={6}><h1>No Users found</h1></td></tr>
                    }
                </tbody>
            </table>
      </div> 
    </div> );
}

export default Users;