import './Map.scss'
function Map() {
    return ( 
        <div className="mapIframe" data-aos="zoom-in" >
            <iframe title='florida' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.8793647817097!2d-82.41642882359614!3d28.05870780990539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c7bec3c6ffd3%3A0xb1f4741e5c51906a!2sUniversity%20of%20South%20Florida!5e0!3m2!1sen!2sin!4v1695678593620!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        
        </div>
     );
}

export default Map;