import React,{useEffect} from 'react';
import {useSelector} from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { useNavigate } from 'react-router-dom'
import { TextInputComponent } from '../../utilities/MiniComponents/InputComponent';
import './commonStyling.scss'
import './Login.scss';
import Spinner from '../Spinner';


const validate = values =>{
    const errors = {};
    if(!values.userName)
        errors.userName = "*Enter UserName!"
    if(!values.password)
        errors.password = "*Enter Password!";
    if(values.password && (values.password.length <8 || values.password.length >15))
        errors.password = "Enter Password Between 8 to 15!";
    return errors;
}
function Login(props) {
    const navigate = useNavigate();
    const storeAuth = useSelector(state=>state.reducer_auth);

   const {handleSubmit} = props;
   useEffect(()=>{
    if(storeAuth.loginData && storeAuth.loginData.isSuccess){
        localStorage.setItem("token",storeAuth.loginData.token);
        localStorage.setItem("userName",storeAuth.loginData.userName);
        localStorage.setItem("role",storeAuth.loginData.role);
        navigate('/admin/dashboard')
       }
   })
   if(storeAuth.showSpinner)
    return <div className='spinnerFullWidthContainer'>
            <Spinner/>
            </div>
    return ( 
        <div className="login_container">
            <form className="login_main" onSubmit={handleSubmit}>
                <h3 className="login_heading">Admin Login</h3>
                {storeAuth.loginError && storeAuth.loginError && <div className='error text-center mb-2'>{storeAuth.loginError}</div>}
                    <Field type={"text"} component={TextInputComponent} placeholder="Enter username or email" name="userName" className="text_box"/>
                    <Field type={"password"} component={TextInputComponent} placeholder="Enter password" name="password" className="text_box"/>

                <div>
                    <button disabled={!props.anyTouched} type="submit" className='button-border-filled login-btn'> LOGIN</button>
                </div>
               
            </form>
        </div>
     );
}
const LoginForm = reduxForm({
    form: 'login',
    validate
  })(Login)
export default LoginForm;