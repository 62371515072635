import Axios from 'axios';
import md5 from 'md5'
import { logout,HIDE_SPINNER,SHOW_SPINNER } from './LogoutAction';
export const LOGGING_IN = "LOGGING_IN";
export const LOGGING_IN_SUCCESS = "LOGGING_IN_SUCCESS";
export const LOGGING_IN_FAILED = "LOGGING_IN_FAILED";
export const FETCHING_USERS = "FETCHING_USERS";
export const FETCHING_USER_SUCCESEED = "FETCHING_USER_SUCCESEED";
export const FETCHING_USER_FAILED = "FETCHING_USER_FAILED";
export const DELETING_USER = "DELETING_USER";
export const DELETING_USER_SUCCESS = "DELETING_USER_SUCCESS";
export const DELETING_USER_FAILED = "DELETING_USER_FAILED";
export const FETHING_ROLES = "FETHING_ROLES";
export const FETHING_ROLES_SUCCESS = "FETHING_ROLES_SUCCESS";
export const FETHING_ROLES_FAILED = "FETHING_ROLES_FAILED";
export const REGISTERING_START = "REGISTERING_START";
export const REGISTERING_SUCCEEDED ="REGISTERING_SUCCEEDED";
export const REGISTERING_FAILED ="REGISTERING_FAILED";
export const REGISTERING_RESET ="REGISTERING_RESET";
export const FETCHING_USERS_BY_ID = "FETCHING_USERS_BY_ID";
export const FETCHING_USER_BY_ID_SUCCESEED = "FETCHING_USER_BY_ID_SUCCESEED";
export const FETCHING_USER_BY_ID_FAILED = "FETCHING_USER_BY_ID_FAILED";
export const UPDATING_USER_START = "UPDATING_USER_START"
export const UPDATING_USER_SUCCEEDED = "UPDATING_USER_SUCCEEDED"
export const UPDATING_USER_FAILED = "UPDATING_USER_FAILED"
export const UPDATE_USER_RESET ="UPDATE_USER_RESET";


const loginAction=(type,payload)=>{
    return {type,payload}
}

export const DeletingUser_Action = (userName)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type:DELETING_USER})
            await Axios.delete(window.location.origin+'/api/deleteUser/'+userName,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            });
            dispatch({type:DELETING_USER_SUCCESS});
            dispatch(gettingUser_Action());
            dispatch({type:HIDE_SPINNER})

        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response){
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                    dispatch(logout())
                dispatch({type:DELETING_USER_FAILED})
            }
            else
            dispatch({type:DELETING_USER_FAILED})
        }
    }
}

export const logginFetching_Action = (user,password)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type:LOGGING_IN})
            let eventsData = await Axios.post(window.location.origin+'/api/loginUser',{
                userName: user,
                password: md5(password)
            });
            dispatch(loginAction(LOGGING_IN_SUCCESS,eventsData.data))
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            dispatch(loginAction(LOGGING_IN_FAILED,e.response.data.message))
            else
            dispatch(loginAction(LOGGING_IN_FAILED,e.message))
        }
    }
}

export const gettingUser_Action =()=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type:FETCHING_USERS})
            let usersData = await Axios.get(window.location.origin+'/api/getAllUser',{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            });
            dispatch({type: FETCHING_USER_SUCCESEED,payload:usersData.data})
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                    dispatch(logout())
                dispatch(loginAction(FETCHING_USER_FAILED,e.response.data.message))
            }
            else
            dispatch(loginAction(FETCHING_USER_FAILED,e.message))
        }
    }
}

export const gettingUserRoles_Action =()=>{
    return async(dispatch)=>{
        try{
            dispatch({type:FETHING_ROLES})
            let usersRolesData = await Axios.get(window.location.origin+'/api/getRoles');
            dispatch({type: FETHING_ROLES_SUCCESS,payload:usersRolesData.data})
        }
        catch(e){
            if(e.response)
            {
                dispatch(loginAction(FETHING_ROLES_FAILED,e.response.data.message))
            }
            else
                dispatch(loginAction(FETHING_ROLES_FAILED,e.message))
        }
    }
}

export const registerUser_Action =(user)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type:REGISTERING_START})
            let usersData = await Axios.post(window.location.origin+'/api/registerUser',user,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }   
            });
            dispatch({type: REGISTERING_SUCCEEDED,payload:usersData.data})
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                    dispatch(logout())
                dispatch(loginAction(REGISTERING_FAILED,e.response.data.message))
            }
            else
            dispatch(loginAction(REGISTERING_FAILED,e.message))
        }
    }
}

export const getUserByUserName_Action =(userName)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type:FETCHING_USERS_BY_ID})
            let usersData = await Axios.get(window.location.origin+'/api/getUserByUserName/'+userName,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            });
            dispatch({type: FETCHING_USER_BY_ID_SUCCESEED,payload:usersData.data})
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                    dispatch(logout())
                dispatch(loginAction(FETCHING_USER_BY_ID_FAILED,e.response.data.message))
            }
            else
            dispatch(loginAction(FETCHING_USER_BY_ID_FAILED,e.message))
        }
    }
}
export const updateUser_Action =(userName,user)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type:UPDATING_USER_START})
            let usersData = await Axios.put(window.location.origin+'/api/updateUser/'+userName,user,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }   
            });
            dispatch({type: UPDATING_USER_SUCCEEDED,payload:usersData.data})
            dispatch(getUserByUserName_Action(userName)) 
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                    dispatch(logout())
                dispatch(loginAction(UPDATING_USER_FAILED,e.response.data.message))
            }
            else
            dispatch(loginAction(UPDATING_USER_FAILED,e.message))
        }
    }
}
