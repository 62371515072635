import './App.scss';
import React,{useEffect} from 'react';
import Homepage from './Components/Homepage';
import {useDispatch} from 'react-redux';
import AOS from 'aos';
import {Route,BrowserRouter as Router, Routes} from 'react-router-dom'
import 'aos/dist/aos.css';
import UnderConstruction from './Components/UnderConstruction';
import NotFound from './Components/NotFound';
import Login from './Components/admin/Login';

import { logginFetching_Action } from "./actions/AuthAction";
import Dashboard from './Components/admin/Dashboard';
import DashBoardMain from './Components/admin/DashboardMain';
import Users from './Components/admin/Users';
import EventsAdmin from './Components/admin/EventsAdmin';
import AddUser from './Components/admin/AddUser';
import UpdateUser from './Components/admin/UpdateUser';
import AddEvents from './Components/admin/AddEvents';
import UpdateEvents from './Components/admin/UpdateEvents';
import Terms from './Components/Terms';

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  const dispatch = useDispatch()
  const handleClick = (data)=>{
    dispatch(logginFetching_Action(data.userName,data.password))
    }

  return (
      <div className="App">
        <Router>
          <Routes>
            <Route path='/' element={<Homepage/>} />
            <Route path='/blogs' element={<UnderConstruction/>} />
            <Route path='/admin/login-me' element={<Login onSubmit={(data)=>handleClick(data)}/>} />
            <Route path='/admin/dashboard' element={<Dashboard/>} >
                <Route path='' element={<DashBoardMain/>} />
                <Route path='users' element={<Users/>} />
                <Route path='events' element={<EventsAdmin/>} />
                <Route path='addUser' element={<AddUser/>} />
                <Route path='updateUser/:userName' element={<UpdateUser/>} />
                <Route path='addEvent' element={<AddEvents/>} />
                <Route path='updateEvent/:eventId' element={<UpdateEvents/>} />
            </Route>
            <Route path='/termsAndCondition' element={<Terms/>}/>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
