import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Switch } from '@mui/material';

export const TextInputComponent =(props)=>{
    return (
        <div className="text_box_container">
            <TextField type={props.type} className={props.className} {...props.input} label={props.placeholder} variant="outlined" required = {props.required} />
            {(props.meta.touched || props.meta.dirty) && props.meta.error &&
            <div className="error">{props.meta.error}</div>}
        </div>
    )
}

export const TextAreaComponent = (props)=>{
    return(
        <div className="text_box_container">
             <textarea className={props.className} {...props.input} placeholder={props.placeholder} />
            {(props.meta.touched || props.meta.dirty) && props.meta.error &&
            <div className="error">{props.meta.error}</div>}
        </div>
    )
}


export const InputTypeSwitch = (props)=>{

    let {input : {value} } = props
    return(
        <div className={props.className}>
            <Switch checked={value ? true : false} {...props.input}/> Active
        </div>
    )
}


export const SelectComponent = (props)=>{
    return (
        <div className='text_box_container'>
            <InputLabel sx={{position:'relative',top:"20px"}}>{props.label}</InputLabel>
            <Select
                label={props.label}
                className= {props.className+" selectorInput"}
                {...props.input}
            >
                {props.children && props.children.map((ele)=>{
                    return (
                        <MenuItem key={ele.role} value={ele.role}>{ele.role}</MenuItem>
                    )
                })}
                
            </Select>
            {(props.meta.touched || props.meta.dirty) && props.meta.error &&
            <div className="error">{props.meta.error}</div>}
        </div>
    )
}