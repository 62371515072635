import {useDispatch,useSelector} from 'react-redux'
import useLoginStatus from "../../utilities/hooks/useLoginStatus";
import './AddUser.scss'
import { updateUser_Action, getUserByUserName_Action } from "../../actions/AuthAction";
import UpdateUserForm from './UpdateUserForm';
import {useParams} from 'react-router-dom'
import { useEffect } from 'react';
import Spinner from '../Spinner';
function UpdateUser() {
    const dispatch = useDispatch();
    const {userName} = useParams()
    const reduxAuth = useSelector(state=>state.reducer_auth);
    const userData = reduxAuth.userDataForUserName;
    const userDataCopy = {...userData}
    const userDataCopy2 = {...userDataCopy}
    useLoginStatus();
    useEffect(()=>{
        dispatch(getUserByUserName_Action(userName))
    },[dispatch, userName])
    const handleSubmit = (values)=>{
        dispatch(updateUser_Action(userName, values))
    }
    if(reduxAuth.loginData.role !=='Admin'){
        return <div className="section-separator text-center">You are not eligible to access this page</div>
    }
    if(reduxAuth.showSpinner)
    {
        return <div className='spinnerFullWidthContainer'> <Spinner/></div>
    }
    return ( <div className="section-separator">
       <h1 className="text-center">Update User {userName}</h1> 
       
       <UpdateUserForm onSubmit={handleSubmit} userData={userDataCopy2}/>
    </div> );
}

export default UpdateUser;