import React from "react";
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram,faTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'
import {Link} from 'react-router-dom'
function Footer({homeLink,aboutLink,eventLink,galleryLink,blogLink}) {
    return ( 
        <section className="footer-container ">
            <footer>
            <Link to="/termsAndCondition"><div className="TandC text-center mb-3">Read all Terms & condition</div></Link><br/><br/>
                <div className="container">
                    <div className="row">
                        <aside className="col-12 col-md-4">
                               <img alt="logo" src="/assets/images/logo.png" className="footerLogoImg"/>
                        </aside>
                        <aside className="col-12 col-md-4">
                               <div className="footer-col-text">
                                    <a href={homeLink}>
                                        Home
                                    </a>
                                </div>
                               <div className="footer-col-text">
                                <a href={aboutLink}>
                                    About Us
                                </a>
                               </div>
                               <div className="footer-col-text">
                                <a href={eventLink}>
                                    Events
                                </a>
                                </div>
                               <div className="footer-col-text">
                                <a href={galleryLink}>
                                    Gallery
                                </a>
                               
                                </div>
                               <div className="footer-col-text">
                                    <a href={blogLink}>
                                            Blogs
                                    </a>
                                </div>
                        </aside>
                        <aside className="col-12 col-md-4">
                               <div className="footer-col-text">
                                    <a href="https://www.instagram.com/partybulls555/" target={"_blank"} rel="noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} className="icon-footer" />
                                    </a>
                                </div>
                               <div className="footer-col-text">
                                <a href="https://twitter.com/PartyBulls555" target={"_blank"} rel="noreferrer">
                                <FontAwesomeIcon icon={faTwitter} className="icon-footer" />
                                </a>
                               </div>
                               <div className="footer-col-text">
                                <a href="https://www.youtube.com/channel/UC7kr7N6nbQeXpWnkhyXWrfA" target={"_blank"} rel="noreferrer">
                                <FontAwesomeIcon icon={faYoutube} className="icon-footer" />
                                </a>
                                </div>
                        </aside>
                   
                    <div className="TandC  rights_reserved text-center">&copy; All Rights are Reserved</div>
                    </div>
                </div>
            </footer>
        </section>
     );
}

export default Footer;