import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';
import Popper from 'popper.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {Provider} from 'react-redux'
import {persistor,store} from './store/store'
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
          main: 'rgb(238, 69, 100)',
        },
        secondary: {
          main: green[500],
        },
      },
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
       <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
             <App />
          </PersistGate>
        </ThemeProvider>
    </Provider>
);

