import {configureStore} from '@reduxjs/toolkit';
import {  combineReducers } from 'redux'
import { reducer_event } from '../reducers/reducer_event';
import {reducer_auth} from '../reducers/reducer_auth'
import { reducer as formReducer } from 'redux-form'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {USER_LOGOUT} from '../actions/LogoutAction'


const persistConfig = {
  key: 'root',
  storage,
}
const appReducerCombined = combineReducers({reducer_event,reducer_auth,form:formReducer})
const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducerCombined(undefined, action)
  }

  return appReducerCombined(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore(
    {
        reducer: persistedReducer ,
        middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: false,
          thunk: true
        })
    }
    )

    export const persistor = persistStore(store)