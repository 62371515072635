import {Field,reduxForm} from 'redux-form'
import {TextInputComponent, SelectComponent} from '../../utilities/MiniComponents/InputComponent'
import {useDispatch,useSelector} from 'react-redux'
import { gettingUserRoles_Action, REGISTERING_RESET } from '../../actions/AuthAction';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
const validate =(value)=>{
    let error = {};
    if(!value.userName)
        error.userName = 'Please Enter UserName!'
    if(!value.password)
        error.password = 'Please Enter Password!'
    if(value.password && value.password.length < 8)
    error.password = 'Password length should be 8 or more!'
    if(!value.firstName)
         error.firstName = 'Please Enter First Name!'
    if(!value.email)
    error.email = 'Please Enter Email!'

    var reg = /^[1-9]{1}[0-9]{9}$/;     
    if(!reg.test(value.phone))
        error.phone = 'Please Enter Valid Phone Number!'
    if(!value.lastName)
    error.lastName = 'Please Enter Last Name!'
    return error;
    
}
function RegisterUserForm(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const reducerAuth = useSelector(state=>state.reducer_auth)
    const rolesState = reducerAuth.userRoles;
    const registerError = reducerAuth.registerError;
    const registerResponse = reducerAuth.registerResponse
    const [roles,setRoles] = useState([])
    const {initialize} = props;
    useEffect(()=>{
        setRoles(rolesState)
    },[rolesState])
    useEffect(()=>{
        if(roles.length)
        initialize({"role":roles[1].role})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[roles])
    useEffect(()=>{
        dispatch(gettingUserRoles_Action())
    },[dispatch])
    useEffect(()=>{
        if(registerResponse && registerResponse.isSuccess){
            dispatch({type:REGISTERING_RESET})
            navigate('/admin/dashboard/users')
        }
    },[dispatch, navigate, registerResponse])
    
    return ( 
    <form onSubmit={props.handleSubmit} className={'formRegistration'}>
        {registerError && <div className='error text-center'>{registerError}</div>}
        <Field type={"text"} component={TextInputComponent} placeholder="Enter Username*" name="userName" className="text_box"/>
        <Field type={"text"} component={TextInputComponent} placeholder="Enter First Name*" name="firstName" className="text_box "/>
        <Field type={"text"} component={TextInputComponent} placeholder="Enter Last Name*" name="lastName" className="text_box"/>
        <Field type={"email"} component={TextInputComponent} placeholder="Enter EmailId*" name="email" className="text_box"/>
        <Field type={"phone"} component={TextInputComponent} placeholder="Enter Phone Number*" name="phone" className="text_box"/>
        <Field type={"password"} component={TextInputComponent} placeholder="Enter Password*" name="password" className="text_box"/>
        <Field component={SelectComponent} name="role" className="text_box" label={"Select the role of admin"}>
            {roles}
        </Field>
        <button className='button-border-filled mt-3' disabled={!props.valid} type='submit'>Register</button>
    </form>
     );
}
const myRegistrationForm = reduxForm(
    {
        form: "registerUser",
        validate
    }
)

export default myRegistrationForm(RegisterUserForm);