
import {reduxForm,Field} from 'redux-form'
import {TextInputComponent,TextAreaComponent,InputTypeSwitch} from '../../utilities/MiniComponents/InputComponent'
import {useDispatch,useSelector} from 'react-redux'
import { useEffect, useState } from 'react';
import {UPDATE_EVENT_RESET,eventFetchByIdAction,updateEventAction} from "../../actions/EventsAction"
import { useNavigate, useParams } from 'react-router-dom';
import useLoginStatus from "../../utilities/hooks/useLoginStatus";
import Spinner from '../Spinner';

const validator = (value) =>{
    const error= {};
    if(!value.title){
        error.title = "Enter Title Please!"
    }
    if(!value.desc)
    {
        error.desc  = "Enter Description Please!"
    }
    if(!value.date){
        error.date = "Please Choose Date of Event!"
    }
    if(!value.time){
        error.time = "Please Choose Time of Event!"
    }
    if(!value.venue){
        error.venue = "Please Choose Venue of Event!"
    }
    if(!value.link){
        error.link = "Please Choose Link of Event!"
    }
    return error;

    
}

function UpdateEvents(props) {
    const {handleSubmit,initialize } = props;
    useLoginStatus();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const EventStore = useSelector(state=>state.reducer_event)
    const AuthStore = useSelector(state=>state.reducer_auth)
    const fetchEventByIdSuccess = EventStore.fetchEventById;
    const fetchEventByIdError = EventStore.fetchEventByIdError;
    const updatingEventSuccess = EventStore.updatingEventSuccess;

    const [img,setImg] = useState()
    const {eventId} = useParams()

    useEffect(()=>{
        dispatch(eventFetchByIdAction(eventId))
    },[dispatch, eventId])

    useEffect(()=>{
        initialize(fetchEventByIdSuccess)
    },[initialize,fetchEventByIdSuccess])

    useEffect(()=>{
        if(updatingEventSuccess){
            dispatch({type: UPDATE_EVENT_RESET})
            navigate('/admin/dashboard/events')
        }
    },[updatingEventSuccess, dispatch, navigate])


    const fileChangeHandler =(e)=>{
        setImg(e.target.files[0])
    }
    const submitHandler = (values)=>{
        const valuesCopy = {...values};
        if(img)
        {
            const formData = new FormData();
            const imgName =new Date()+img.name
            formData.append('img',img,imgName)
            valuesCopy.img = imgName;
            dispatch(updateEventAction(formData, valuesCopy.eventId,valuesCopy))
        }
        else
             dispatch(updateEventAction(null,valuesCopy.eventId,valuesCopy))
    }
    if(fetchEventByIdError){
        return (
        <div className="section-separator">
            <h1 className='text-center'>Update Events</h1>
            <h2 className="text-center error">{fetchEventByIdError}</h2>
        </div> 
        )
    }
    if(AuthStore.showSpinner)
    {
        return <div className='spinnerFullWidthContainer'> <Spinner/></div>
    }
    return ( 
        <div>
            <div className="section-separator">
                <h1 className='text-center'>Update Events</h1>
            </div>
            <div className='text-center'><a target="_blank" href='https://onlinehtmleditor.dev/' rel="noreferrer">Refer This site for editing (Description only) </a></div>
            <form onSubmit={handleSubmit(submitHandler)} className={'formRegistration'}>
                {EventStore.updatingEventError && <div className='text-center error'> {EventStore.updatingEventError} </div>}
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Title of Event*" name="title" className="text_box"/>
                <Field component={TextAreaComponent} placeholder="Enter Description of Event*" name="desc" className="textarea_box"/>
                <Field type={"date"} component={TextInputComponent}  name="date" className="text_box"/>
                <Field type={"time"} component={TextInputComponent}  name="time" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Venue of Event*" name="venue" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Link of Google Form*" name="link" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Cost of Entry" name="cost" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Coupon If any" name="coupon" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Terms & condition of Event" name="termsAndCondition" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter DressCode of Event" name="dressCode" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Ticket Type of Event" name="ticketType" className="text_box"/>
                <Field component={InputTypeSwitch} name="active" className='mt-3'/>
                <img className='img-preview' alt='img-preview' src={window.location.origin + '/'+fetchEventByIdSuccess.img}/>
                <div className='mt-3'>
                    <label htmlFor="uploadImage" className='button-border-filled btn-small'>Upload Image</label>
                    <input type={"file"}  id="uploadImage" className='d-none' name='img' accept='image/*' onChange={fileChangeHandler}/>
                    <span className={ img ? 'm-1' : 'm-1 error'}>{img ? img.name : ((fetchEventByIdSuccess && fetchEventByIdSuccess.img )&& fetchEventByIdSuccess.img.name)}</span>
                </div>
                <button type='submit' className='button-border-theme mt-3' disabled={!(props.valid) }>Update Event</button>
            </form>
        </div>
     );
}
const EventUdateFormContainer = reduxForm({
    form: 'addEventForm',
    validate: validator
})

export default EventUdateFormContainer(UpdateEvents);