import './Bull.scss'
function Bull() {
    return (    
        <>
            <img data-aos="zoom-in"  src="/assets/images/bullT.png" alt="bull" className="bullImg"/>
            <h1 data-aos="fade-up" className="text-center bullHeading">Party Bull Invites You!</h1> 
         </>
    )
}

export default Bull;