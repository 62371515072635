import { 
    LOGGING_IN_SUCCESS,
    LOGGING_IN_FAILED,
    FETCHING_USER_SUCCESEED,
    FETCHING_USER_FAILED,
    FETHING_ROLES_SUCCESS,
    FETHING_ROLES_FAILED,
    REGISTERING_FAILED,
    REGISTERING_SUCCEEDED,
    REGISTERING_RESET,
    FETCHING_USER_BY_ID_SUCCESEED,
    FETCHING_USER_BY_ID_FAILED,
    UPDATING_USER_SUCCEEDED,
    UPDATING_USER_FAILED,
    UPDATE_USER_RESET
} from "../actions/AuthAction"

import {SHOW_SPINNER,HIDE_SPINNER} from "../actions/LogoutAction"
const AuthInitialState = {
    loginData: {},loginError:"",
    usersData:[],usersError:"",
    userRoles:[],userRolesError:"",
    registerResponse:"",registerError:"",
    userDataForUserName:{},userDataForUserNameError:"",
    updateUserResponse:{},updateUserError:"",
    showSpinner:false,
}
export const reducer_auth = (state = AuthInitialState, action) => {
    switch (action.type) {
        case SHOW_SPINNER:
            return {...state,showSpinner:true}
        case HIDE_SPINNER:
            return {...state,showSpinner:false}
        case LOGGING_IN_SUCCESS:
            return {...state,loginData : action.payload, loginError:""}
        case LOGGING_IN_FAILED:
            return {...state,loginData : {}, loginError: action.payload}
        case FETCHING_USER_SUCCESEED:
            return {...state,usersData:action.payload.users,usersError:""}
        case FETCHING_USER_FAILED:
            return {...state,usersData:[],usersError:action.payload}
        case FETHING_ROLES_SUCCESS:
            return {...state,userRoles:action.payload,userRolesError:""}
        case FETHING_ROLES_FAILED:
            return {...state,userRoles:[],userRolesError:action.payload}
        case REGISTERING_FAILED:
            return {...state,registerError:action.payload,registerResponse:""}
        case REGISTERING_SUCCEEDED:
            return {...state,registerError:"",registerResponse:action.payload}
        case REGISTERING_RESET:
            return {...state,registerError:"",registerResponse:""}
        case FETCHING_USER_BY_ID_SUCCESEED:
            return {...state,userDataForUserName:action.payload.users,userDataForUserNameError:""}
        case FETCHING_USER_BY_ID_FAILED:
            return {...state,userDataForUserName:{},userDataForUserNameError:action.payload}
        case UPDATING_USER_FAILED:
            return {...state,updateUserError:action.payload,updateUserResponse:""}
        case UPDATING_USER_SUCCEEDED:
            return {...state,updateUserError:"",updateUserResponse:action.payload}
        case UPDATE_USER_RESET:
            return {...state,updateUserError:"",updateUserResponse:{}}
        default:
            return state
    }
}
