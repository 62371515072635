import Axios from 'axios';
import { logout,SHOW_SPINNER,HIDE_SPINNER } from './LogoutAction';

export const FETCH_EVENT = "FETCH_EVENT";
export const SUCCESSFUL_EVENT = "SUCCESSFUL_EVENT";
export const FAILED_EVENT = "FAILED_EVENT";
export const DELETING_EVENT = "DELETING_EVENT"
export const DELETING_SUCCEED = "DELETING_SUCCEED"
export const DELETING_FAILED = "DELETING_FAILED"
export const ADDING_EVENT = "ADDING_EVENT"
export const ADDING_EVENT_SUCCEED = "ADDING_EVENT_SUCCEED"
export const ADDING_EVENT_FAILED = "ADDING_EVENT_FAILED"
export const ADD_EVENT_RESET = "ADD_EVENT_RESET"
export const UPDATING_EVENT = "UPDATING_EVENT"
export const UPDATING_EVENT_SUCCEED = "UPDATING_EVENT_SUCCEED"
export const UPDATING_EVENT_FAILED = "UPDATING_EVENT_FAILED"
export const UPDATE_EVENT_RESET = "UPDATE_EVENT_RESET"
export const FETCHING_EVENT_BY_ID = "FETCHING_EVENT_BY_ID"
export const FETCHING_EVENT_BY_ID_SUCCEED = "FETCHING_EVENT_BY_ID_SUCCEED"
export const FETCHING_EVENT_BY_ID_FAILED = "FETCHING_EVENT_BY_ID_FAILED"




const eventAction=(type,payload)=>{
    return {type,payload}
}

export const eventFetchAction = ()=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            let eventsData = await Axios.get(window.location.origin+'/api/getEvents');
            dispatch(eventAction(SUCCESSFUL_EVENT,eventsData.data))
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            dispatch(eventAction(FAILED_EVENT,e.response.data.message))
            else
            dispatch(eventAction(FAILED_EVENT,e.message))
        }
    }
}

export const eventFetchByIdAction = (eventId)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type: FETCHING_EVENT_BY_ID});
            let eventsData = await Axios.get(window.location.origin+'/api/getEventsById/'+eventId);
            dispatch(eventAction(FETCHING_EVENT_BY_ID_SUCCEED,eventsData.data))
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            dispatch(eventAction(FETCHING_EVENT_BY_ID_FAILED,e.response.data.message))
            else
            dispatch(eventAction(FETCHING_EVENT_BY_ID_FAILED,e.message))
        }
    }
}


export const deleteEventAction = (eventId,img)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            let eventsData = await Axios.delete(window.location.origin+'/api/deleteEvent/'+eventId+"/"+img,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            });
            dispatch(eventAction(DELETING_SUCCEED,eventsData.data))
            dispatch(eventFetchAction());
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                dispatch(logout())
                dispatch(eventAction(DELETING_FAILED,e.response.data.message))
            }
            else
            dispatch(eventAction(DELETING_FAILED,e.message))
        }
    }
}

export const addEventAction =(event,formData)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type: ADDING_EVENT})   
            let eventsData = await Axios.post(window.location.origin+'/api/addEvent/',event,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            });
            await Axios.post(window.location.origin+'/api/fileUpload/',formData,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            })
            dispatch(eventAction(ADDING_EVENT_SUCCEED,eventsData.data))
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                dispatch(logout())
                dispatch(eventAction(ADDING_EVENT_FAILED,e.response.data.message))
            }
            else
            dispatch(eventAction(ADDING_EVENT_FAILED,e.message))
        }
    }
}

export const updateEventAction =(formData , eventId, data)=>{
    return async(dispatch)=>{
        try{
            dispatch({type:SHOW_SPINNER})
            dispatch({type: UPDATING_EVENT})   
            let eventsData = await Axios.put(window.location.origin+'/api/updateEvent/'+eventId,data,{
                headers:{
                    Authorization: `bearer ${localStorage.getItem('token')}` 
                }
            });
            if(formData){
                await Axios.post(window.location.origin+'/api/fileUpload/',formData,{
                    headers:{
                        Authorization: `bearer ${localStorage.getItem('token')}` 
                    }
                })
            }
            
            dispatch(eventAction(UPDATING_EVENT_SUCCEED,eventsData.data))
            dispatch({type:HIDE_SPINNER})
        }
        catch(e){
            dispatch({type:HIDE_SPINNER})
            if(e.response)
            {
                if(e.response.data.message === 'Not Authorized!' || e.response.data.message === 'Forbidden!')
                dispatch(logout())
                dispatch(eventAction(UPDATING_EVENT_FAILED,e.response.data.message))
            }
            else
            dispatch(eventAction(UPDATING_EVENT_FAILED,e.message))
        }
    }
}