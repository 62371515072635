
import { 
    SUCCESSFUL_EVENT,FAILED_EVENT,
    DELETING_SUCCEED,DELETING_FAILED,
    ADDING_EVENT_FAILED,ADDING_EVENT_SUCCEED,ADD_EVENT_RESET,
    FETCHING_EVENT_BY_ID_FAILED,FETCHING_EVENT_BY_ID_SUCCEED,
    UPDATING_EVENT_SUCCEED,UPDATING_EVENT_FAILED, UPDATE_EVENT_RESET
 } from "../actions/EventsAction"
const EventInitialState = {
    fetchData:[],fetchDataError:"",
    deleteStatus:{},deleteError:"",
    addingEvent:{},addingEventError:"",
    fetchEventById:"",fetchEventByIdError:"",
    updatingEventSuccess:"",updatingEventError:""
}
export const reducer_event = (state = EventInitialState, action) => {
    switch (action.type) {
        case SUCCESSFUL_EVENT:
            return {...state,fetchData : action.payload, fetchDataError:""}
        case FAILED_EVENT:
            return {...state,fetchData : [], fetchDataError: action.payload}
        case DELETING_SUCCEED:
            return {...state,deleteStatus:action.payload, deleteError:""}
        case DELETING_FAILED:
            return {...state,deleteStatus:{}, deleteError:action.payload};
        case ADDING_EVENT_SUCCEED:
            return {...state,addingEventError:"", addingEvent:action.payload};
        case ADDING_EVENT_FAILED:
            return {...state,addingEvent:{}, addingEventError:action.payload};
        case ADD_EVENT_RESET:
            return {...state,addingEvent:{}, addingEventError:""}
        case FETCHING_EVENT_BY_ID_SUCCEED:
            return {...state,fetchEventById:action.payload,fetchEventByIdError:""}
        case FETCHING_EVENT_BY_ID_FAILED:
            return {...state,fetchEventById:"",fetchEventByIdError:action.payload}
        case UPDATING_EVENT_SUCCEED:
            return {...state,updatingEventSuccess:action.payload,updatingEventError:""}
        case UPDATING_EVENT_FAILED:
            return {...state,updatingEventSuccess:"",updatingEventError:action.payload}
        case UPDATE_EVENT_RESET:
            return {...state,updatingEventSuccess:"",updatingEventError:""}
        default:
            return state
    }
}

