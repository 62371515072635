import React from "react";
import Header  from "./Header";
import './Banner.scss'
import { useMediaQuery } from "react-responsive";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Banner() {
    const isMobile = useMediaQuery({ query: `(max-width: 1150px)` });
   

    return ( 
        <section className={isMobile ? "banner" : ""} id="home">
        <div className="videoContainer">
        {!isMobile &&
            <video className="bannerVideo" loop autoPlay muted playsInline={true}>
                <source src="assets/images/video.mp4" type="video/mp4"/>
            </video>
        }
        </div>
 
        <Header></Header>
                <Carousel 
                autoPlay={true} 
                emulateTouch={false} 
                infiniteLoop={true} 
                showThumbs={false} 
                interval={4000}
                swipeable={false}
                stopOnHover={false}
                showStatus={false}
                useKeyboardArrows={true}
                renderThumbs={(imagesS)=> imagesS}
                >
                    <div className="banner-text">
                       
                        <div className="banner-mid-text" data-aos="flip-up" data-aos-duration="1400">
                            Events of the clubs
                        </div>
                        <div className="banner-bottom-text" data-aos="flip-up" data-aos-duration="1400">
                            "Dance the Night Away, Every Night."
                        </div>
                        <a href="#events"><button className="button-border-filled banner-btn" data-aos="zoom-in">
                            View Events
                        </button></a>
                    </div>
     
                    <div className="banner-text">
                       
                        <div className="banner-mid-text" data-aos="flip-up" data-aos-duration="1400">
                            Ultimate Pool Party Bash
                        </div>
                        <div className="banner-bottom-text" data-aos="flip-up" data-aos-duration="1400">
                            "🌊 Dive into Florida Sunny Vibes: Join Us for a Splashin' Pool Party! 🌞🏊‍♀️"
                        </div>
                        <a href="#blog"><button className="button-border-filled banner-btn" data-aos="zoom-in">
                            View Blog
                        </button></a>
                    </div>
                
                    <div className="banner-text">
                        <div className="banner-mid-text" data-aos="flip-up" data-aos-duration="1400">
                             Explore with us
                        </div>
                        <div className="banner-bottom-text" data-aos="flip-up" data-aos-duration="1400">
                            "Step Inside the Celebration: Exploring Unforgettable Party Moments"
                        </div>
                        <a href="#gallery"><button className="button-border-filled banner-btn" data-aos="zoom-in">
                            Gallery
                        </button></a>
                    </div>
            </Carousel>    
    </section>
     );
}

export default Banner;