import React from "react";
import './NotFound.scss'

function NotFound() {
    return (  <div className="container-nf">
    <div>

        <h1 className="notFoundText">
            Not Found
        </h1>
        <div style={{textAlign:'center',fontSize:'arial',color:'aqua'}}>
            <a href="/">Home</a>
        </div>
    </div>
</div> );
}

export default NotFound;
