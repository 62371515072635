import {reduxForm,Field} from 'redux-form'
import {TextInputComponent,TextAreaComponent,InputTypeSwitch} from '../../utilities/MiniComponents/InputComponent'
import {useDispatch,useSelector} from 'react-redux'
import { useEffect, useState } from 'react';
import {addEventAction,ADD_EVENT_RESET} from "../../actions/EventsAction"
import { useNavigate } from 'react-router-dom';


const validator = (value) =>{
    const error= {};
    if(!value.title){
        error.title = "Enter Title Please!"
    }
    if(!value.desc)
    {
        error.desc  = "Enter Description Please!"
    }
    if(!value.date){
        error.date = "Please Choose Date of Event!"
    }
    if(!value.time){
        error.time = "Please Choose Time of Event!"
    }
    if(!value.venue){
        error.venue = "Please Choose Venue of Event!"
    }
    if(!value.link){
        error.link = "Please Choose Link of Event!"
    }
    return error;

    
}

function AddEventForm(props) {
    const {handleSubmit,initialize } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [img,setImage] =  useState(null);
    const EventStore = useSelector(state=>state.reducer_event)
    const addingEventSuccess = EventStore.addingEvent
    useEffect(()=>{
        initialize({"active":true})
    },[initialize])
    useEffect(()=>{
        if(addingEventSuccess && Object.keys(addingEventSuccess).length){
            dispatch({type: ADD_EVENT_RESET})
            navigate('/admin/dashboard/events')
        }
    },[addingEventSuccess, dispatch, navigate])
   const fileChangeHandler = (e)=>{
        setImage(e.target.files[0])
   }
    const submitHandler = (values)=>{
        const valuesCopy = {...values};
        const formData = new FormData();
        const imgName =new Date()+img.name
        formData.append('img',img,imgName)
        valuesCopy.img = imgName;
        dispatch(addEventAction(valuesCopy,formData))
    }

    return ( 
        <div>
            <div className='text-center'><a target="_blank" href='https://onlinehtmleditor.dev/' rel="noreferrer">Refer This site for editing (Description only) </a></div>
            <form onSubmit={handleSubmit(submitHandler)} className={'formRegistration'}>
                {EventStore.addingEventError && <div className='text-center error'> {EventStore.addingEventError} </div>}
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Title of Event*" name="title" className="text_box"/>
                <Field component={TextAreaComponent} placeholder="Enter Description of Event*" name="desc" className="textarea_box"/>
                <Field type={"date"} component={TextInputComponent}  name="date" className="text_box"/>
                <Field type={"time"} component={TextInputComponent}  name="time" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Venue of Event*" name="venue" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Link of Google Form*" name="link" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Cost of Entry" name="cost" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Coupon If any" name="coupon" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Terms & condition of Event" name="termsAndCondition" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter DressCode of Event" name="dressCode" className="text_box"/>
                <Field type={"text"} component={TextInputComponent} placeholder="Enter Ticket Type of Event" name="ticketType" className="text_box"/>
                <div className='mt-3'>
                    <label htmlFor="uploadImage" className='button-border-filled btn-small'>Upload Image</label>
                    <input type={"file"}  id="uploadImage" className='d-none' name='img' accept='image/*' onChange={fileChangeHandler} required/>
                    <span className={ img ? 'm-1' : 'm-1 error'}>{img ? img.name : "Please Enter File*"}</span>
                </div>
                <Field component={InputTypeSwitch} name="active" className='mt-3'/>
                <button type='submit' className='button-border-theme mt-3' disabled={!(props.valid && img) }>Add Event</button>
            </form>
        </div>
     );
}
const EventFormContainer = reduxForm({
    form: 'addEventForm',
    validate: validator
})

export default EventFormContainer(AddEventForm);