import { Link } from "react-router-dom";

function Terms() {
    return ( <div className="section-separator p-3">
                <h1 className="text-center">Terms and Conditions</h1>:
                <p>An Intellectual Property clause will inform users that the contents, logo and other visual media you created is your property and is protected by copyright laws.</p>
                <p>A Termination clause will inform users that any accounts on your website and mobile app, or users' access to your website and app, can be terminated in case of abuses or at your sole discretion.</p>
                <p>A Governing Law clause will inform users which laws govern the agreement. These laws should come from the country in which your company is headquartered or the country from which you operate your website and mobile app.</p>
                <p>A Links to Other Websites clause will inform users that you are not responsible for any third party websites that you link to. This kind of clause will generally inform users that they are responsible for reading and agreeing (or disagreeing) with the Terms and Conditions or Privacy Policies of these third parties.</p>
                <p>If your website or mobile app allows users to create content and make that content public to other users, a Content clause will inform users that they own the rights to the content they have created. This clause usually mentions that users must give you (the website or mobile app developer/owner) a license so that you can share this content on your website/mobile app and to make it available to other users.</p>
                <Link to={'/'} ><p className="text-center">Home</p></Link>
             </div> 
                );
}

export default Terms;