import  { useState } from "react";
import { useEffect } from "react";
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
function useLoginStatus() {
    const navigate = useNavigate();
    const reduxAuth = useSelector(state=>state.reducer_auth);
    const userName = localStorage.getItem("userName")
    const [loggedIn,setIsLoggedIn] =  useState("loading")
    useEffect(()=>{
        if(!(reduxAuth.loginData && reduxAuth.loginData.isSuccess && userName)){
            setIsLoggedIn(false) 
            navigate('/admin/login-me', { replace: true });
        }
        else
        {
            setIsLoggedIn(true)
        }
            
    },[reduxAuth, userName,navigate])
   return (
    loggedIn
   )
}

export default useLoginStatus;