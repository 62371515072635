import React from "react";
import { Link } from "react-router-dom";
import './underConstruction.scss';
function UnderConstruction() {
    return ( 
        <div className="underConstructionBanner">
            <div className="construction_container">
                <div className="imageConstruction">
                    <img src="/assets/images/construct.png" alt="construction" />
                    <div className="contruction_text">
                        Site is under maintainence<br/>
                        <Link to={'/'}>Home</Link>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default UnderConstruction;